<script lang="ts" setup>
  import {BASE_URL} from 'src/core/api'
  import {ref} from 'vue'

  const emit = defineEmits(['changeBaseUrl'])

  const apiURL = ref(BASE_URL)
  const env = import.meta.env.VITE_ENVIRONMENT

  const validate = () => {
    emit('changeBaseUrl', apiURL.value)
  }
</script>

<template>
  <div
    class="top-0 left-0 w-full bg-[#2E302F] text-white px-4 py-2 shadow-md flex justify-start items-center gap-4 z-50"
  >
    <p class="font-bold">[Env : {{ env }}]</p>
    <form
      class="flex gap-4 items-center"
      @submit.prevent="validate"
    >
      <label> URL de l'API:</label>
      <input
        v-model="apiURL"
        class="px-2 border rounded-md text-black text-base w-56"
        placeholder="Entrez l'URL de l'API..."
      />
      <button
        type="submit"
        class="px-2 py-1 bg-[#FFCB00] text-black rounded"
      >
        Valider
      </button>
    </form>
  </div>
</template>
